import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "fileName",
    "identityProofDocumentType",
    "validationErrors"
  ];

  requireDocumentType(event) {
    if (this.hasIdentityProofDocumentTypeTarget) {
      try {
        this.identityProofDocumentTypeTarget.required = true
      } catch (e) {}
    }
  }

  displayFileName(event) {
    if (this.hasFileNameTarget) {
      try {
        this.fileNameTarget.innerText = event.target.files[0].name;
      } catch (e) {}
    }
  }

  clearValidationErrors() {
    if (this.hasValidationErrorsTarget) {
      try {
        this.validationErrorsTarget.innerText = "";
      } catch (e) {}
    }
  }
}
