import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="users--platform-administrator-profile-form"
export default class extends Controller {
  static targets = [
    "createTabButton",
    "findTabButton",
    "createUserTab",
    "findUserTab",
  ];

  connect() {}

  handleClick(event) {
    const { target } = event;
    const {
      createUserTabTarget,
      findUserTabTarget,
      createTabButtonTarget,
      findTabButtonTarget,
    } = this;

    if (target === createTabButtonTarget) {
      this.hideTab(findUserTabTarget);
      this.enableFieldsOf(createUserTabTarget);
      this.revealTab(createUserTabTarget);
      this.disableFieldsOf(findUserTabTarget);
      this.setAsCurrent(createTabButtonTarget);
      this.setAsInactive(findTabButtonTarget);
    } else {
      this.revealTab(findUserTabTarget);
      this.enableFieldsOf(findUserTabTarget);
      this.hideTab(createUserTabTarget);
      this.disableFieldsOf(createUserTabTarget);
      this.setAsCurrent(findTabButtonTarget);
      this.setAsInactive(createTabButtonTarget);
    }
  }

  hideTab(tab) {
    tab.classList.remove("opacity-100");
    tab.classList.add("opacity-0", "hidden");
  }

  revealTab(tab) {
    tab.classList.add("opacity-100");
    tab.classList.remove("hidden");
  }

  disableFieldsOf(tab) {
    tab
      .querySelectorAll("input")
      .forEach((input) => input.setAttribute("disabled", true));
  }

  enableFieldsOf(tab) {
    tab
      .querySelectorAll("input")
      .forEach((input) => input.removeAttribute("disabled"));
  }

  setAsCurrent(tabButton) {
    tabButton.classList.add("text-primary-500", "bg-primary-50");
    tabButton.classList.remove("text-gray-500", "bg-white");
    tabButton
      .querySelectorAll(".bottomBorder")[0]
      .classList.add("bg-primary-500");
  }

  setAsInactive(tabButton) {
    tabButton.classList.add("text-gray-500", "bg-white");
    tabButton.classList.remove("text-primary-500", "bg-primary-50");
    tabButton
      .querySelectorAll(".bottomBorder")[0]
      .classList.remove("bg-primary-500");
  }
}
