import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "validationErrors"
  ];

  clear(event) {
    event.target.reset();
  }

  clearValidationErrors() {
    if (this.hasValidationErrorsTarget) {
      this.validationErrorsTargets.forEach((element) => {
        try {
          element.innerText = "";
        } catch (e) {}
      });
    }
  }
}
