import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-switcher"
export default class extends Controller {
  static targets = ["menu"];
  static values = {
    profilesCount: Number,
  };

  initialize() {
    this.menuIsOpen = false;
  }

  connect() {}

  handleClick(event) {
    event.preventDefault();
    const noOtherProfilesToSwitchTo = this.profilesCountValue < 1;
    if (noOtherProfilesToSwitchTo) {
      return;
    }

    this.menuIsOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu() {
    const { menuTarget } = this;
    menuTarget.classList.remove("hidden", "h-0");
    menuTarget.classList.add("h-36");
    this.menuIsOpen = true;
  }

  closeMenu() {
    const { menuTarget } = this;
    menuTarget.classList.remove("h-36");
    menuTarget.classList.add("h-0", "hidden");
    this.menuIsOpen = false;
  }
}
