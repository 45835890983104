import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Controller } from '@hotwired/stimulus';
import FileInput from '@uppy/file-input';
import Form from '@uppy/form';
import French from '@uppy/locales/lib/fr_FR';
import Uppy from '@uppy/core';

export default class extends Controller {
  initialize() {
    const directUploadInputForm = document.getElementById('direct-upload-input-form');
    let filePrefix = '';
    let allowedFileTypes = null;

    if (directUploadInputForm && directUploadInputForm !== '') {
      if (directUploadInputForm.dataset.prefix && directUploadInputForm.dataset.prefix !== '') {
        filePrefix = `${directUploadInputForm.dataset.prefix}/`;
      }

      if (directUploadInputForm.dataset.accept && directUploadInputForm.dataset.accept !== '') {
        allowedFileTypes = directUploadInputForm.dataset.accept.split(',');
      }

      delete directUploadInputForm.dataset.accept;
      delete directUploadInputForm.dataset.prefix;
    }

    this.uppy = new Uppy({
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      autoProceed: true,
      locale: French,
      restrictions: {
        allowedFileTypes: allowedFileTypes,
      },
      onBeforeFileAdded: (currentFile, files) => ({
        ...currentFile,
        name: `${filePrefix}${currentFile.name}`,
      }),
    });

    this.uppy.use(FileInput, {
      target: '#direct-upload-input-form',
      pretty: true,
      inputName: 'file',
    }).use(AwsS3Multipart, {
      companionUrl: '/',
    }).use(Form, {
      addResultToForm: true,
      getMetaFromForm: false,
      resultName: 'file_data',
      submitOnSuccess: true,
      target: '#direct-upload-input-form',
      triggerUploadOnSubmit: false,
    });
  }

  connect() {
    this.uppy.on('complete', (result) => {
      if (result.failed.length === 0) {
        document.querySelector('#uploaded-documents-feedback').innerHTML = directUploadInputForm.dataset.translationSuccess;
      }
    });
  }
}
