import consumer from "./consumer"

document.addEventListener("turbo:load", () => {
  const exportButton = document.getElementById("export-button")
  if (exportButton === null) {
    return;
  }

  const profile_id = exportButton.dataset.profileId;

  consumer.subscriptions.create({channel: "ExportChannel", profile_id: profile_id}, {
    exportContainer: null,

    initialized() {
      this.exportContainer = document.getElementById("export-container");
    },

    connected() {
      const exportLoader = document.getElementById("exportLoader");

      exportButton.onclick = () => {
        exportButton.classList.add("hidden");
        exportLoader.classList.remove("hidden");
      }
    },

    received(data) {
      exportLoader.classList.add("hidden");
      exportButton.classList.remove("hidden");

      if (data.status === "success") {
        const htmlLink = `<a href="${data.export_url}" id="export-link" class="underline">Export - ${data.created_at}</a>`;
        this.exportContainer.innerHTML = htmlLink;
        document.getElementById("export-link").click();
      } else {
        const htmlError = "<p>An error occured. Please try again later.</p>";
        this.exportContainer.innerHTML = htmlError;
      }
    }
  });
});
