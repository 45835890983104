import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile-sidebar"
export default class extends Controller {
  static targets = [
    "closeButton",
    "backdrop",
    "drawer",
    "openButton",
    "openButtonWrapper",
  ];

  connect() {}

  openMobileSidebar(event) {
    event.preventDefault();
    const {
      backdropTarget,
      drawerTarget,
      closeButtonTarget,
      openButtonWrapperTarget,
    } = this;
    backdropTarget.classList.remove("opacity-0");
    backdropTarget.classList.remove("hidden");
    backdropTarget.classList.add("opacity-100");
    drawerTarget.classList.remove("-translate-x-full");
    drawerTarget.classList.remove("hidden");
    drawerTarget.classList.add("translate-x-0");
    closeButtonTarget.classList.remove("opacity-0");
    closeButtonTarget.classList.remove("hidden");
    closeButtonTarget.classList.add("opacity-100");
    openButtonWrapperTarget.classList.remove("z-50");
  }

  closeMobileSidebar(event) {
    event.preventDefault();
    const {
      backdropTarget,
      drawerTarget,
      closeButtonTarget,
      openButtonWrapperTarget,
    } = this;
    backdropTarget.classList.remove("opacity-100");
    backdropTarget.classList.add("opacity-0");
    backdropTarget.classList.add("hidden");
    drawerTarget.classList.remove("translate-x-0");
    drawerTarget.classList.add("-translate-x-full");
    drawerTarget.classList.add("hidden");
    closeButtonTarget.classList.remove("opacity-100");
    closeButtonTarget.classList.add("opacity-0");
    closeButtonTarget.classList.add("hidden");
    openButtonWrapperTarget.classList.add("z-50");
  }
}
